import { createContext, useEffect, useReducer } from "react";
import { AppConfig, Theme } from "../Models/AppConfig";
export const AppContext = createContext({} as [AppConfig, any]);

function appConfigReducer(
    state: AppConfig,
    action: { type: string; payload: any }
) {
    switch (action.type) {
        case "setSession":
            return { ...state, session: action.payload.session };
        // case "setTheme":
        //     return { ...state, theme: action.payload.theme };
        // case "setScene":
        //     return { ...state, scene: action.payload.scene };
        default:
            return state;
    }
}

export function AppProvider({ children }: any) {
    const [appConfig, dispatch] = useReducer(appConfigReducer, {} as AppConfig);
    // useEffect(() => {

    //     // set theme
    //     dispatch({
    //         type: "setTheme",
    //         payload: {
    //             theme: {
    //                 isDarkTheme: true,
    //                 isRtl: false,
    //                 name: Theme.Dark,
    //             },
    //         },
    //     });
    // }, []);
    return (
        <AppContext.Provider value={[appConfig, dispatch]}>
            {children}
        </AppContext.Provider>
    );
}

import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "./Contexts/AppContext";
import LoginPage from "./Pages/LoginPage";
import QRCodesPage from "./Pages/QRCodesPage";

import "bootstrap/dist/css/bootstrap.min.css";
import CentralizedLayout from "./Layout/CentralizedLayout";
import { ProtectedRoute } from "./Components/ProtectedRoute";
import AdminPage from "./Pages/AdminPage";
import axios from "axios";

const queryClient = new QueryClient();

axios.defaults.headers.common["Authorization"] =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJoYXNoIjoiNDQxMzZmYTM1NWIzNjc4YTExNDZhZDE2ZjdlODY0OWU5NGZiNGZjMjFmZTc3ZTgzMTBjMDYwZjYxY2FhZmY4YSIsImlhdCI6MTY4MTcwMTQ1OX0.QM1eSkosSHC9sujghrIfWEdqNL1zK2WUuh_D7XSeFr8";
axios.defaults.headers.common["X-API-KEY"] =
    "16a16a37-15c1-44f3-91fa-a6a2606ce27a";

function App() {
    return (
        <AppProvider>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Routes>
                        <Route index element={<LoginPage />} />
                        <Route element={<ProtectedRoute />}>
                            <Route
                                path="qrcodes"
                                element={
                                    <CentralizedLayout>
                                        <QRCodesPage />
                                    </CentralizedLayout>
                                }
                            />
                        </Route>
                        <Route element={<ProtectedRoute />}>
                            <Route
                                path="admin"
                                element={
                                    <CentralizedLayout>
                                        <AdminPage />
                                    </CentralizedLayout>
                                }
                            />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </QueryClientProvider>
        </AppProvider>
    );
}

export default App;

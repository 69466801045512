import { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { AppContext } from "../Contexts/AppContext";
import { useMagic } from "../Hooks/useMagic";
import { Link, useNavigate } from "react-router-dom";

export default function NavBar() {
    const [appConfig, dispatch] = useContext(AppContext);
    const [email, setEmail] = useState<string>("");
    const magic = useMagic();
    const navigate = useNavigate();

    useEffect(() => {
        const getUserMetadata = async () => {
            const metadata = await magic.user.getMetadata();
            if (metadata) {
                const { email } = metadata;
                setEmail(email as string);
            }
        };
        getUserMetadata();
    }, [email]);

    return (
        <Navbar>
            <Container>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: "100px" }}
                        navbarScroll>
                        <Nav.Link as={Link} to="/qrcodes">Home</Nav.Link>
                        {appConfig.session && (appConfig.session as any).role === "ADMIN" ? (
                            <Nav.Link as={Link} to="/admin" style={{color: "red"}}>Admin</Nav.Link>
                        ) : null}
                        <Nav.Link
                            onClick={async () => {
                                await magic.user.logout();
                                dispatch({
                                    type: "setSession",
                                    payload: {
                                        session: null,
                                    },
                                });
                                navigate("/");
                            }}>
                            Log Out
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <div>{email}</div>
            </Container>
        </Navbar>
    );
}

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useMagic } from "../Hooks/useMagic";
import { MagicUserMetadata } from "magic-sdk";
import { AppContext } from "../Contexts/AppContext";
export function useFetchSession() {
    const magic = useMagic();
    const [appConfig, dispatch] = useContext(AppContext);
    const [done, setDone] = useState(false);

    const initializeMagicSession = async () => {
        const isLoggedIn = await magic.user.isLoggedIn();
        if (isLoggedIn) {
            const metadata = await magic.user.getMetadata();
            dispatch({
                type: "setSession",
                payload: {
                    session: metadata,
                },
            });
            const didToken = await magic.user.generateIdToken();
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL1}/acl`,
                {
                    headers: {
                        Authorization: `Bearer ${didToken}`,
                    },
                }
            );
            if (response.status === 200) {
                dispatch({
                    type: "setSession",
                    payload: {
                        session: { ...appConfig.session, role: response.data },
                    },
                });
                setDone(true);
            }
        }
    };

    useEffect(() => {
        console.log("initializing magic session")
        initializeMagicSession();
    }, []);

    return { done, initializeMagicSession };
}

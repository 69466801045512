import { Container } from "react-bootstrap";
import NavBar from "../Components/NavBar";

const CentralizedLayout: React.FC<any> = ({ children }) => {
    return (
        <Container>
            <NavBar />
            {children}
        </Container>
    );
};
export default CentralizedLayout;

import { useState } from "react";
import { useMagic } from "./useMagic";

export function useDIDToken() {
    const magic = useMagic();
    const [didToken, setDIDToken] = useState<string | null>(null);
    const [lastFetched, setLastFetched] = useState<number>(0); // unix timestamp

    const fetchDIDToken = async () => {
        // cache the DID token for 10 mins
        const now = Date.now();
        if (now - lastFetched < 10 * 60 * 1000) {
            return;
        }
        const didToken = await magic.user.generateIdToken();
        setDIDToken(didToken);
        setLastFetched(now);
    };

    fetchDIDToken();

    return didToken;
}

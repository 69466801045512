import { useContext } from "react";
import { AppContext } from "../Contexts/AppContext";
import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute: React.FC<any> = ({ children }) => {
    const [appConfig] = useContext(AppContext);
    if (!appConfig.session) {
        return <Navigate to={"/"} replace />;
      }
      return <Outlet />;
};

import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Organization } from "../Entities/Organization";
import { useDIDToken } from "./useDIDToken";
export default function useOrganizations() {
    const didToken = useDIDToken();
    const { data, status, refetch } = useQuery<Organization[] | undefined | null>(
        "fetchOrganizations",
        async () => {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL1}/organization/list`,
                {
                    headers: {
                        Authorization: `Bearer ${didToken}`,
                    },
                }
            );
            if (response.status !== 200) {
                throw new Error("Error fetching organizations");
            }
            return response.data as Organization[];
        },
        { enabled: true, refetchOnWindowFocus: false }
    );

    return { organizations: data, status, refetch };
}

import axios from "axios";
import { useQuery } from "react-query";
import { Organization } from "../Entities/Organization";
import { useDIDToken } from "./useDIDToken";
export default function useOrganization(organizationDomain: string) {
    const didToken = useDIDToken();
    const { data, status, refetch } = useQuery<Organization | undefined | null>(
        [organizationDomain],
        async () => {
            if (!organizationDomain || organizationDomain.length === 0) return null;
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL1}/organization/${organizationDomain}`,
                {
                    headers: {
                        Authorization: `Bearer ${didToken}`,
                    },
                }
            );
            if (response.status !== 200) {
                throw new Error("Error fetching organization");
            }
            return response.data as Organization;
        },
        { enabled: false, refetchOnWindowFocus: false }
    );

    return { organization: data, status, refetch };
}

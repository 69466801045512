import { useNavigate } from "react-router-dom";
import { useMagic } from "../Hooks/useMagic";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Container, Row, Col } from "react-bootstrap";
import { SyntheticEvent, useEffect, useState } from "react";
import { Loading } from "../Components/Loading";
import { useFetchSession } from "../Hooks/useFetchUser";

const LoginPage: React.FC<any> = () => {
    const navigate = useNavigate();
    const magic = useMagic();
    const [loading, setLoading] = useState<boolean>(false);
    const { done: doneFetchSession, initializeMagicSession } =
        useFetchSession();
    useEffect(() => {
        if (doneFetchSession) {
            setLoading(false);
            navigate("/qrcodes");
        }
    }, [doneFetchSession]);

    const onFormSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        try {
            const formData = new FormData(e.target as HTMLFormElement);
            const email = formData.get("emailAddress") as string;
            await magic.auth.loginWithEmailOTP({
                email,
                showUI: true,
            });
            await initializeMagicSession();
        } catch (error: any) {
            console.log(error);
        }
    };
    return (
        <Container>
            {loading ? (
                <Loading />
            ) : (
                <Row
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: "100vh" }}>
                    <Col xs lg="4">
                        <h4>QR Generation / Redemption System</h4>

                        <Form onSubmit={onFormSubmit}>
                            <Form.Group
                                className="mb-3"
                                controlId="emailAddress">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    name="emailAddress"
                                    type="email"
                                    placeholder="Enter email"
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Login
                            </Button>
                        </Form>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default LoginPage;

import { Container, Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import axios from "axios";
import { useState, useEffect, SyntheticEvent } from "react";
import { useQuery } from "react-query";
import { Loading } from "../Loading";
import Select from "react-select";
import { Machine } from "../../Entities/Machine";
import { useMagic } from "../../Hooks/useMagic";

type Product = {
    id: string;
    name: string;
};

type Presale = {
    quantity: number;
    reference: string;
    product_id: string;
    expiry: number;
    restricted_machine_ids: string;
    size: number;
};

const reactQueryOptions = {
    enabled: false,
    refetchOnWindowFocus: false,
};

const OptionsPanel: React.FC<{setCodes: any}> = ({setCodes}) => {
    const magic = useMagic();
    const [machines, setMachines] = useState<Machine[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [organization, setOrganization] = useState<string>("");
    
    const {
        error: fetchProductsError,
        isLoading: fetchProductsIsLoading,
        refetch: fetchProducts,
    } = useQuery<any, Error>(
        "getproducts",
        async () => {
            const response = await axios.post(
                `${process.env.REACT_APP_RV_BASE_URL}/products/all`
            );
            if (response.status === 200) {
                const products = await response.data.data;
                setProducts(
                    products.sort((a: any, b: any) =>
                        a.name > b.name ? 1 : -1
                    )
                );
            } else {
                alert("Error fetching products");
                throw new Error("Error fetching products");
            }
        },
        reactQueryOptions
    );

    useEffect(() => {
        (async () => {
            const didToken = await magic.user.generateIdToken();
            const {email} = await magic.user.getMetadata();
            const domain = email?.split("@")[1];
            const {data: organizationDomain, status} = await axios.get(
                `${process.env.REACT_APP_API_URL1}/organization/${domain}`,
                {
                    headers: {
                        Authorization: `Bearer ${didToken}`,
                    },
                }
            );
            if (status === 200 && typeof organizationDomain === "object") {
                setMachines(organizationDomain.machines.map((m: string) => Object.create({
                    machine_id: m
                })));
            }
            setOrganization(domain ?? "");
        })();
        fetchProducts();
    }, []);

    const onFormSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const didToken = await magic.user.generateIdToken();
        const formData = new FormData(e.target as HTMLFormElement);

        const reference = formData.get("reference")?.valueOf() as string;

        // Create presale and generate QR codes
        /*
        await axios.post(
            `${process.env.REACT_APP_API_URL1}/sale/${organization}/${reference}`,
            {
                headers: {
                    Authorization: `Bearer ${didToken}`,
                },
            },
        );
        */

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/presale`,
            {
                reference: formData.get("reference")?.valueOf() as string,
                expiry: new Date(
                    formData.get("expiryDate")?.valueOf() as string
                ).getTime(),
                quantity: formData.get("quantity")?.valueOf() as string,
                size: formData.get("size")?.valueOf() as string,
                restricted_machine_ids: formData
                    .get("machine")
                    ?.valueOf() as string,
                product_id: formData.get("product")?.valueOf() as string,
                org: formData.get("org")?.valueOf() as string,
            }
        );
        if (response.status === 200) {
            const presalesData = response.data.presales.data.qrcodes;
            console.log(presalesData);
            setCodes(presalesData);
        } else {
            console.error(response);
            alert("Error creating presale");
            throw new Error("Error creating presale");
        }
    };

    return (
        <Container>
            {fetchProductsIsLoading ? (
                <Loading />
            ) : (
                <Form onSubmit={onFormSubmit}>
                    <Row className="align-items-center">
                        <Col xs="auto">
                            <Form.Label
                                visuallyHidden>
                                Reference
                            </Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>Reference</InputGroup.Text>
                                <Form.Control
                                    id="reference"
                                    placeholder="Reference"
                                    name="reference"
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Form.Label
                                visuallyHidden>
                                Expiry Date
                            </Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>Expiry Date</InputGroup.Text>
                                <Form.Control
                                    id="expiryDate"
                                    placeholder="Expiry Date"
                                    name="expiryDate"
                                    type="date"
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Form.Label
                                visuallyHidden>
                                Quantity
                            </Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>Quantity</InputGroup.Text>
                                <Form.Control
                                    id="quantity"
                                    placeholder="Quantity"
                                    name="quantity"
                                    type="number"
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="auto">
                            <Form.Label
                                visuallyHidden>
                                Size
                            </Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Text>Size</InputGroup.Text>
                                <Form.Control
                                    id="size"
                                    placeholder="Size"
                                    name="size"
                                    type="number"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col xs="4">
                            <Form.Label
                                visuallyHidden>
                                Machine
                            </Form.Label>
                            <InputGroup className="mb-6">
                                <InputGroup.Text>Machines</InputGroup.Text>
                                <Select
                                    isMulti
                                    name="machine"
                                    className="basic-multi-select form-control"
                                    classNamePrefix="select"
                                    options={machines.map((m) =>
                                        Object.assign(
                                            {},
                                            {
                                                value: m.machine_id,
                                                label: m.machine_id,
                                            }
                                        )
                                    )}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="4">
                            <Form.Label
                                visuallyHidden>
                                Product
                            </Form.Label>
                            <InputGroup className="mb-6">
                                <InputGroup.Text>Products</InputGroup.Text>
                                <Select
                                    name="product"
                                    className="form-control"
                                    classNamePrefix="select"
                                    options={products.map((m) =>
                                        Object.assign(
                                            {},
                                            { value: m.id, label: m.name }
                                        )
                                    )}
                                />
                            </InputGroup>
                        </Col>
                        <Col xs="4">
                            <Form.Label
                                visuallyHidden>
                                Organization
                            </Form.Label>
                            <InputGroup className="mb-6">
                                <InputGroup.Text>Organization</InputGroup.Text>
                                <Select
                                    name="org"
                                    className="form-control"
                                    classNamePrefix="select"
                                    options={[
                                        {
                                            value: organization,
                                            label: organization,
                                        }
                                    ]}
                                    defaultValue={{
                                        value: organization,
                                        label: organization,
                                    }}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="align-items-center mt-2">
                        <Col xs="auto">
                            <Button type="submit" className="mb-2">
                                Generate
                            </Button>
                        </Col>
                    </Row>
                </Form>
            )}
        </Container>
    );
};

export default OptionsPanel;
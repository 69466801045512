import { Container, Row } from "react-bootstrap";
import { useState } from "react";
import OptionsPanel from "../Components/QRCodes/OptionPanel";
import CodesTable from "../Components/QRCodes/CodesTable";

const QRCodesPage: React.FC<any> = () => {
    /* 
    Add Form component within Container containing these fields:
    - Reference (Text)
    - Expiry Date (DateTime) - Picker
    - Quantity - (Number)
    - Size - (Number)
    - Machines List - (MultiSelect)
    - Products List - (Single Select)
    - Generate Button - Activates the QRCode generation process
    - Export as CSV Button - Exports the generated QR Codes as CSV
    - Export as XLXS Button - Exports the generated QR Codes as XLXS

    Generate Button: Submit button type. Function handler onGenerate() is bound to this button.

    onGenerate() calls HTTP POST to /presale with data payload:
    {
        expiry: <Date in YYYY-MM-DD format>,
        quantity: <Number>,
        size: <Number>,
        reference: <String>,
        product_id: <Select product select>,
        restricted_machine_ids: <List of selected machines>
        org: "rvs.com.sg"
    }
    */
    const [codes, setCodes] = useState<string[]>([]);

    return (
        <Container>
            <OptionsPanel setCodes={setCodes} />
            <Row className="d-flex align-items-center justify-content-center">
                <CodesTable codes={codes} />
            </Row>
        </Container>
    );
};
export default QRCodesPage;

import { QRCodeCanvas } from "qrcode.react";
import { Container } from "react-bootstrap";

const CodesTable: React.FC<{ codes: string[] }> = ({ codes }) => {
    return (
        <Container>
            {codes.map((code) => (
                <QRCodeCanvas id={code} size={200} value={code} includeMargin={true} />
            ))}
        </Container>
    );
};

export default CodesTable;

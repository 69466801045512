import axios from "axios";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select";
import Creatable from "react-select/creatable";
import { SyntheticEvent, useEffect, useState } from "react";
import useMachines from "../Hooks/useMachines";
import { Machine } from "../Entities/Machine";
import { Organization } from "../Entities/Organization";
import useOrganizations from "../Hooks/useOrganizations";
import useOrganization from "../Hooks/useOrganization";
import { useDIDToken } from "../Hooks/useDIDToken";

const AdminPage: React.FC<any> = () => {
    const didToken = useDIDToken();
    const [selectedOrganization, setSelectedOrganization] = useState<
        string | null
    >(null);
    const [selectedMachines, setSelectedMachines] = useState<string[]>([]);

    const {
        organization,
        status: fetchOrganizationStatus,
        refetch: fetchOrganization,
    } = useOrganization(selectedOrganization ?? "");

    const { machines, status: fetchMachinesStatus } = useMachines();
    const {
        organizations,
        status: fetchOrganizationsStatus,
        refetch: fetchOrganizations,
    } = useOrganizations();

    const onOrganizationChange = async (newValue: any, metaValue: any) => {
        setSelectedMachines([]);
        const { action, removedValues } = metaValue;
        switch (action) {
            case "remove-value":
                setSelectedOrganization(null);
                break;
            case "select-option":
                setSelectedOrganization(newValue.value);
                break;
        }
    };

    useEffect(() => {
        if (!selectedOrganization) {
            return;
        }
        (async () => {
            await fetchOrganization();
        })();
    }, [selectedOrganization]);

    useEffect(() => {
        console.log("organization", organization);
        if (!organization) {
            return;
        }
        setSelectedMachines(organization.machines ?? []);
    }, [organization]);

    const onMachineChange = (newValue: any, metaValue: any) => {
        const { action, removedValue } = metaValue;

        switch (action) {
            case "remove-value":
                setSelectedMachines(
                    selectedMachines.filter(
                        (machine) => machine !== removedValue.value
                    )
                );
                break;
            case "select-option":
                setSelectedMachines([
                    ...selectedMachines,
                    metaValue.option.value as string,
                ]);
                break;
        }
    };

    const onFormSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const domain = formData.get("organization")?.valueOf() as string;
        const machines = formData.getAll("machines").valueOf() as string[];

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL1}/organization/${domain}`,
            {
                domain,
                machines,
            },
            {
                headers: {
                    Authorization: `Bearer ${didToken}`,
                },
            }
        );
        if (response.status === 200) {
            console.log(response.data);
        } else {
            console.error(response);
            alert("Error saving organization");
            throw new Error("Error saving organization");
        }
    };
    return (
        <Container>
            <Row className="d-flex align-items-center justify-content-center">
                <Col xs lg="8">
                    <h4>Admin Page</h4>
                    <Form onSubmit={onFormSubmit}>
                        <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword">
                            <Form.Label>Select Organization</Form.Label>
                            <Creatable
                                name="organization"
                                options={(organizations ?? [])
                                    .sort((a: Organization, b: Organization) =>
                                        a.domain > b.domain ? 1 : -1
                                    )
                                    .map((organization: Organization) => {
                                        return {
                                            value: organization.domain,
                                            label: organization.domain,
                                        };
                                    })}
                                onChange={onOrganizationChange}
                                isClearable
                                isLoading={
                                    fetchOrganizationsStatus === "loading"
                                }
                                isDisabled={
                                    fetchOrganizationsStatus === "loading"
                                }
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Machines</Form.Label>
                            <Select
                                name="machines"
                                closeMenuOnSelect={false}
                                isMulti
                                value={selectedMachines.map(
                                    (machineId: string) => {
                                        return {
                                            value: machineId,
                                            label: machineId,
                                        };
                                    }
                                )}
                                options={machines?.map((machine: Machine) => {
                                    return {
                                        value: machine.machine_id,
                                        label: machine.machine_id,
                                    };
                                })}
                                onChange={(newValue, actionMeta) =>
                                    onMachineChange(newValue, actionMeta)
                                }
                                isLoading={
                                    fetchMachinesStatus === "loading" ||
                                    fetchOrganizationStatus === "loading"
                                }
                                isDisabled={
                                    fetchMachinesStatus === "loading" ||
                                    fetchOrganizationStatus === "loading"
                                }
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};
export default AdminPage;

import { Machine } from "../Entities/Machine";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";

const useMachines = (
) => {
    const { data, status, refetch } = useQuery<Machine[] | undefined>(
        "getmachines",
        async () => {
            const response = await axios.post(
                `${process.env.REACT_APP_RV_BASE_URL}/machines/all`
            );
            if (response.status !== 200) {
                throw new Error("Error fetching machines");
            }
            return response.data.data.sort((a: Machine, b: Machine) =>
                a.machine_id > b.machine_id ? 1 : -1
            );
        },
        { enabled: true, refetchOnWindowFocus: false }
    );

    return { machines: data, status, refetch };
};

export default useMachines;
